import React, { useEffect, useState } from 'react';
import Api from '../../../js/Api';

const PackagingChecklist = ({ order, markOrderReady }) => {
  const [checklist, setChecklist] = useState([]);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setChecklist(order.checklist);
  },[]);


  const handleCheck = (id) => {
    const updatedChecklist = checklist.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setChecklist(updatedChecklist);

    // Check if all items are checked
    const allChecked = updatedChecklist.every((item) => item.checked);
    setIsReady(allChecked);
  };

  const markAsReady = async () => {
    if (isReady) {
        markOrderReady(order.invoiceNumber);
    }
  };

  if (!order) {
    return <div className="p-4">Loading order details...</div>;
  }

  return (
    <div className="p-4 text-left w-full">
      <h1 className="text-2xl font-bold mb-4">Packaging Checklist</h1>
      <div>
        {checklist.map((item) => (
          <div key={item.id} className="flex items-center mb-2">
            <input
              type="checkbox"
              id={item.id}
              checked={item.checked}
              onChange={() => handleCheck(item.id)}
              className="form-checkbox h-8 w-8 accent-gt-teal cursor-pointer"
            />
            <label htmlFor={item.id} className="ml-2 text-lg">
              {item.label}
            </label>
          </div>
        ))}
      </div>
      <button
        onClick={markAsReady}
        disabled={!isReady}
        className={`mt-6 px-4 py-2 rounded w-full max-w-xl ${
          isReady ? 'bg-green-700 hover:opacity-70' : 'bg-gray-400 cursor-not-allowed'
        } text-white text-lg`}
      >
        Mark as Ready for Pickup
      </button>
    </div>
  );
};

export default PackagingChecklist;